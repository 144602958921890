<template>
  <div class="page" id="call-survey-list">
    <div class="mb-2 p-3 card header">
      <tabs
        :options="stateOptions"
        :value="filters.isActive"
        @input="onIsActiveChange"
      ></tabs>
      <router-link to="/call-survey/create" class="px-4 py-3 btn btn-primary">
        {{ $t('call-survey.list.create') }}
      </router-link>
    </div>

    <div class="content">
      <data-table
        ref="surveys"
        :data="surveys"
        :tableStyle="{
          'min-height': '400px'
        }"
        :fields="translatedTableFields"
        :loading="isLoading"
        :pagination="pagination"
        @changePage="onPageChange"
      >
        <template v-slot:status="{ row }">
          <status-cell :rowData="row" />
        </template>
        <template v-slot:actions="{ row }">
          <actions-cell
            :rowData="row"
            @refetch="fetchCallSurveys({ loading: true })"
            @showMetrics="onShowMetrics($event, row.id)"
            @showCallsAnalytics="onShowCallsAnalytics($event, row.id)"
          />
        </template>
      </data-table>
    </div>

    <b-modal
      id="modal-xl"
      size="xl"
      :title="$t('call-survey.survey-metrics.title')"
      v-model="showMetrics"
      scrollable
      content-class="overflow-auto"
      hide-footer
      centered
      ignore-enforce-focus-selector="textarea, button, input"
      :dir="dir"
    >
      <survey-metrics :metrics="currentMetrics" :surveyId="surveyId" />
    </b-modal>

    <b-modal
      id="modal-xl"
      size="xl"
      :title="$t('call-survey.survey-analytics.title')"
      v-model="showAnalytics"
      scrollable
      content-class="overflow-auto"
      hide-footer
      centered
      ignore-enforce-focus-selector="textarea, button, input"
      :dir="dir"
    >
      <survey-analytics :metrics="currentAnalytics" :surveyId="surveyId" />
    </b-modal>
  </div>
</template>

<script>
import Tabs from '../../../elements/Tabs.vue'
import DataTable from '../../../elements/Table.vue'
import CallSurveyService from '../../../services/call-survey.service'
import StatusCell from './cells/StatusCell.vue'
import ActionsCell from './cells/ActionsCell.vue'
import SurveyMetrics from './SurveyMetrics.vue'
import SurveyAnalytics from './SurveyAnalytics.vue'
import { mapGetters } from 'vuex'

let interval

export default {
  components: {
    Tabs,
    DataTable,
    StatusCell,
    ActionsCell,
    SurveyMetrics,
    SurveyAnalytics
  },
  data() {
    return {
      filters: {
        isActive: 'active'
      },
      surveys: [],
      isLoading: false,
      pagination: {
        totalPages: 0,
        page: 1
      },
      currentMetrics: {},
      currentAnalytics: {},
      showMetrics: false,
      showAnalytics: false,
      surveyId: null
    }
  },
  computed: {
    ...mapGetters(['currentTenant']),
    dir() {
      return this.$i18n.locale() === 'ar' ? 'rtl' : 'ltr'
    },
    translatedTableFields() {
      return [
        {
          accessor: 'name',
          header: this.$t('call-survey.list.table.name')
        },
        {
          accessor: 'status',
          header: this.$t('call-survey.list.table.status')
        },
        {
          accessor: 'actions',
          header: this.$t('call-survey.list.table.actions'),
          width: '40%'
        }
      ]
    },
    stateOptions() {
      return [
        {
          title: this.$t('call-survey.list.states.active'),
          value: 'active'
        },
        {
          title: this.$t('call-survey.list.states.finished'),
          value: 'finished'
        }
      ]
    }
  },
  methods: {
    onIsActiveChange(value) {
      this.filters.isActive = value
      this.pagination.page = 1
      this.$router.push({ query: { page: 1 } })
      this.fetchCallSurveys({ loading: true })
    },
    onPageChange(page) {
      this.pagination.page = page
      this.fetchCallSurveys({ loading: true })
      this.$router.push({ query: { page } })
    },
    async fetchCallSurveys({ loading = true }) {
      try {
        if (loading) this.isLoading = true
        // fetch call surveys
        const filters = {
          ...this.filters,
          isActive: this.filters.isActive === 'active'
        }
        const res = await CallSurveyService.listAllCallSurveys(
          filters,
          this.pagination.page,
          10
        )
        const { surveys, ...pagination } = res.data
        this.surveys = surveys
        this.pagination = {
          ...this.pagination,
          ...pagination
        }
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message

        console.log(errorMessage)
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    onShowMetrics(metrics, surveyId) {
      this.currentMetrics = metrics
      this.showMetrics = true
      this.surveyId = surveyId
    },
    onShowCallsAnalytics(analytics, surveyId) {
      this.currentAnalytics = analytics
      this.showAnalytics = true
      this.surveyId = surveyId
    }
  },
  mounted() {
    this.pagination.page = this.$route.query.page || 1
    this.fetchCallSurveys({ loading: true })
    interval = setInterval(() => {
      this.fetchCallSurveys({ loading: false })
    }, 10000)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(interval)
    next()
  },
  watch: {
    currentTenant: {
      immediate: true,
      handler() {
        if (!this.currentTenant) return
        this.fetchCallSurveys({ loading: true })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: spin 1s linear infinite;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
</style>
